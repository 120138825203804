<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="page-container">
    <!-- S头部 -->
    <header class="header"
            ref="header">
      <div class="header-left">
        <div class="img-wrap">
          <img ref="logo"
               src="/logo_transparent.png" />
        </div>
        <span>数字智能</span>
      </div>
      <nav class="header-nav">
        <ul @click="navigateTo">
          <li :class="{ active: activeNav === i }"
              v-for="(item, i) in nav"
              :key="i"
              :data-route="item.route">
            {{ item.title }}
          </li>
        </ul>
      </nav>
      <div @click="clickaf043da9">
        <i class="el-icon-user-solid"></i>
        <button>登录</button>
      </div>
    </header>
    <!-- E头部 -->

    <div class="dal-rtv">
      <!-- <div class="daline-img">
      </div> -->
      <router-view> </router-view>
    </div>
    <div id="dlgfafa9d804"
         v-show="vis_afa9d804"
         @click.self="vis_afa9d804 = false">
      <div id="afa9d804">
        <div class="flex-wrapper">
          <img src="../assets/img-a010c8e8.png"
               id="a010c8e8" />
        </div>

        <div class="form__wrap_outer">
          <div class="form__wrap_inner">
            <span class="login-text"> 登录 </span>
            <div class="form-item">
              <el-input v-model="inp_a8effa13"
                        placeholder="请输入用户名">
              </el-input>
            </div>
            <div class="form-item">
              <el-input v-model="inp_f9d1a507"
                        type="password"
                        placeholder="请输入密码">
              </el-input>
            </div>
            <div class="form-remember">
              <el-checkbox v-model="cb_ac2c9386"> 记住用户密码 </el-checkbox>
              <a href=""> 忘记密码？ </a>
            </div>
            <div class="flex-wrapper">
              <el-button class="form-button"
                         @click="clickee136e68()">
                登录
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import { userLogin } from "@/api/login.js";
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import { throttle } from "throttle-debounce";
export default {
  data () {
    return {
      activeNav: undefined,
      nav: [
        {
          title: "首页",
          route: "/Index",
        },
        {
          title: "私域流量建设",
          route: "/Privatetrafficconstruction",
        },
        {
          title: "数字资产化",
          route: "/Digitalcapitalization",
        },
        {
          title: "一体化业务开发平台",
          route: "/Integratedbusinessdevelopmentplatform",
        },
        {
          title: "数智算力网络",
          route: "/edgepower",
        },
        {
          title: "关于我们",
          route: "/Aboutus",
        },
      ],
      vis_afa9d804: false,
      inp_a8effa13: "",
      inp_f9d1a507: "",
      cb_ac2c9386: false,
      //在此注释下方添加自定义变量
      passwd_f9d1a507: "",
      activeEl: undefined,
    };
  },
  mounted () {
    this.activeEl = this.$refs.liActived;
    this.handleScroll();
    const { path } = this.$route;
    this.activeNav = this.nav.findIndex((p) => p.route === path);
    window.addEventListener(
      "scroll",
      throttle(500, false, () => {
        if (document.documentElement.scrollTop) {
          if (document.querySelector(".dal-floatheader")) {
            document.querySelector(".dal-floatheader").style.backgroundColor =
              "#141620";
          }
        } else {
          if (document.querySelector(".dal-floatheader")) {
            document.querySelector(".dal-floatheader").style.backgroundColor =
              "transparent";
          }
        }
      })
    );
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler ({ path }) {
        this.activeNav = this.nav.findIndex((p) => p.route === path);
        this.$nextTick(() => {
          const header = this.$refs.header;
          const logo = this.$refs.logo;
          if ([""].includes(path)) {
            header.style.backgroundColor = "#fff";
            header.style.color = "#000";
            logo.setAttribute("src", "/logo_white.png");
          } else {
            header.style.backgroundColor = "transparent";
            header.style.color = "#fff";
            logo.setAttribute("src", "/logo_transparent.png");
          }
        });
      },
    },
  },
  methods: {
    handleScroll () {
      window.addEventListener("scroll", () => {
        // const height = document.body.offsetHeight
        const header = this.$refs.header;
        const logo = this.$refs.logo;
        const banner = document.querySelector(".banner_wrap");
        if (!banner) return;
        const pageOffset = window.pageYOffset;
        // const bannerHeight = banner.offsetHeight;
        if (pageOffset > 0) {
          header.style.backgroundColor = "#fff";
          header.style.color = "#000";
          logo.setAttribute("src", "/logo_white.png");
        } else {
          header.style.backgroundColor = "transparent";
          header.style.color = "#fff";
          logo.setAttribute("src", "/logo_transparent.png");
        }
      });
    },
    navigateTo (e) {
      const path = e.target.getAttribute("data-route");
      if (this.$route.path === path) return;
      this.$router.push(path);
    },
    async clicka1ac3968 () {
      this.$router.push("Index");
    },
    async clickaec059ff () {
      this.$router.push("Privatetrafficconstruction");
    },
    async clicka01853d5 () {
      this.$router.push("Digitalcapitalization");
    },
    async clickc754bb51 () {
      this.$router.push("Integratedbusinessdevelopmentplatform");
    },
    async clickaf0a8366 () {
      this.$router.push("Aboutus");
    },
    async clicka72cefec () {
      this.$router.push("Newscenter");
    },
    async clickaf043da9 () {
      this.vis_afa9d804 = true;
    },
    async clickee136e68 () {
      // this.$router.push('Afterlanding')
      userLogin({
        username: this.inp_a8effa13,
        password: this.inp_f9d1a507,
      }).then((res) => {
        if (res.access_token) {
          if (this.cb_a5dd) {
            console.log(res);
            localStorage.setItem("userName", this.inp_a8effa13);
            localStorage.setItem("passwd", this.inp_f9d1a507);
          } else {
            localStorage.removeItem("userName");
            localStorage.removeItem("passwd");
          }

          this.$router.push("Afterlanding");
        } else {
          localStorage.removeItem("userName");
          localStorage.removeItem("passwd");
          this.$message("登录失败");
        }
      });
    },
    //在此注释下方添加自定义函数或事件
  },
};
</script>
<style lang="less" scoped>
.daline-img {
  width: 100vw;
  height: 30vh;
  position: relative;
  background-image: url("../assets/img-ab664084.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.form__wrap_outer {
  box-sizing: border-box;
  width: 45vw;
  padding: 4vh 12vw;
  .form__wrap_inner {
    display: flex;
    flex-direction: column;
    font-size: calc(1vw + 0.5em);
    font-family: "PingFang SC";
    padding: 2vh 2vw;
    box-shadow: 0 0 5px #dcdcdc;
    & > * + * {
      margin-top: 1.5em;
    }
    .login-text {
      text-align: center;
      font-family: "PingFang SC";
    }
    .form-remember {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        color: #487eb0;
        font-size: 0.65em;
      }
    }
    .form-button {
      font-family: "PingFang SC";
      font-size: 0.75em;
      color: #fff;
      background-color: rgb(20, 22, 32);
      padding: 1em 0;
      width: 100%;
    }
  }
}

.header {
  box-sizing: border-box;
  align-items: center;
  display: flex;
  height: 80px;
  padding: 0 15vw;
  /* padding: 1em 15vw; */
  z-index: 100;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 0;
  background-color: transparent;
  color: #fff;
  font-size: calc(0.5vw + 0.25rem);

  .img-wrap {
    width: calc(2vw + 0.25em);
    height: 0;
    padding-bottom: calc(2vw + 0.25em);
    img {
      width: 100%;
      padding-bottom: calc(2vw + 0.25em);
      // height: 46px;
    }
  }
  .header-left {
    display: flex;
    // width: 250px;
    // justify-content: space-around;
    align-items: center;
    span {
      margin-left: 0.5em;
      color: inherit;
      font-size: 1.75em;
    }
  }

  .header-nav {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3em;
    color: inherit;

    ul {
      display: flex;

      li {
        margin: 0 0.25em;
        padding: 0.75em 1em;
        cursor: pointer;
        color: inherit;
      }

      li:hover,
      li.active {
        color: #a393ff;
        // opacity: 0.5;
        // background: #fff;
        border-radius: 4px;
        font-weight: 500;
      }
    }
  }

  i {
    font-size: 1.5em;
  }
  button {
    background-color: transparent;
    color: inherit;
    font-size: 1.5em;
    padding: 0.5em;
    border-radius: 4px;
    cursor: pointer;
  }
}

/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a1e8fd65 .dal-floatheader {
  height: 100px;
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: transparent;
}

#a1e8fd65 .dal-rtv {
  flex: 1;
  position: relative;
}

#efa7c5c8 {
  width: 190px;
  height: 46px;
  padding-left: 360px;
  padding-top: 27px;
  padding-bottom: 27px;
  display: inline-block;
  vertical-align: middle;
}

#efa7c5c8 {
  vertical-align: middle;
}

#e8e86907 {
  margin-left: 175px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: inline-block;
  vertical-align: middle;
}

#ae2f046b {
}

#ae2f046b i {
  display: none;
}

#a1ac3968 {
  width: 66px;
  display: inline-block;
  vertical-align: middle;
}

#a1ac3968 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}

#aec059ff {
  width: 136px;
  margin-left: 1px;
  display: inline-block;
  vertical-align: middle;
}

#aec059ff {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}

#a01853d5 {
  width: 118px;
  margin-left: 2px;
  display: inline-block;
  vertical-align: middle;
}

#a01853d5 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}

#c754bb51 {
  width: 190px;
  margin-left: 2px;
  display: inline-block;
  vertical-align: middle;
}

#c754bb51 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}

#af0a8366 {
  width: 100px;
  margin-left: 2px;
  display: inline-block;
  vertical-align: middle;
}

#af0a8366 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}

#a72cefec {
  width: 100px;
  margin-left: 2px;
  display: inline-block;
  vertical-align: middle;
}

#a72cefec {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}

#af043da9 {
  width: 70px;
  margin-left: 46px;
  margin-top: 38px;
  margin-bottom: 34px;
  display: inline-block;
  vertical-align: middle;
}

#af043da9 {
  background-color: transparent;
}

#af043da9 {
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
  border: transparent;
}

#dlgfafa9d804 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

#afa9d804 {
  padding: 2em 0;
  border-radius: 4px 4px 4px 4px;
  background: #fff;
}

#a010c8e8 {
  width: 199px;
  height: 70px;
}

#a010c8e8 {
  vertical-align: middle;
}

#d7704095 {
  width: 900px;
  height: 50vh;
  position: relative;
  margin-top: 60px;
}

#d7704095 {
  background-color: rgb(255, 255, 255, 1);
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
}

#ca423f29 {
  padding-top: 50px;
}

#ca423f29 {
  text-align: center;
  color: #333;
  font-size: 36px;
  font-family: PingFang SC;
}

#d7704095 .el-input {
  width: 696px;
  margin-top: 60px;
  padding-left: 102px;
}

#d7704095 .el-input {
  width: 696px;
  margin-top: 60px;
  padding-left: 102px;
}

#a71815e5 {
  width: 696px;
  position: relative;
  margin-top: 30px;
  padding-left: 102px;
}

#ac2c9386 {
  width: 179px;
  display: inline-block;
  vertical-align: middle;
}

#a907ed32 {
  display: inline-block;
  vertical-align: middle;
}

#a907ed32 {
  text-align: left;
  color: #2b5ed1;
  font-size: 16px;
  font-family: PingFang SC;
}

#ee136e68 {
  margin: 60px 0;
}

#ee136e68 {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 6px 6px 6px 6px;
}

#ee136e68 {
  color: #fff;
  font-size: 30px;
  font-family: PingFang SC;
  border: transparent;
}

/*在此注释下方添加自定义样式*/
.w1218 {
  width: 1218px;
  margin: 0 auto;
}

#efa7c5c8 {
  margin-left: 0;
}
.flex-wrapper {
  display: flex;
  justify-content: center;
}
</style>